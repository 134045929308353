@import '../../../../scss/theme-bootstrap';

.gnav-country-chooser-list {
  a {
    font-family: $font--text;
    font-size: 14px;
    @include breakpoint($landscape-up) {
      font-size: 12px;
    }
    text-transform: none;
    &:hover {
      color: $color-light-black;
    }
    &.gnav-country-chooser-list__item {
      color: $color-neutral-light-gray;
      @include breakpoint($landscape-up) {
        color: $color-darker-gray;
      }
    }
    &.gnav-country-chooser-list__item--default {
      color: $color-neutral-dark-gray;
      @include breakpoint($landscape-up) {
        color: $color-light-black;
      }
    }
  }
}
